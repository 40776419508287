/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


//
// make an app like frame for Dektop version
@media (pointer: fine) {
  ion-app.ion-page {
    margin: auto;
    width: 100%;
    max-width: 30em;
    border: 1px solid #86c140;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;

  }
}
//
//.popover-content {
//
//  color: coral;
//}
//
//.popover-content{
//  left: 25% !important;
//  color: coral !important;
//}

//@media (pointer: fine) {
//  app-root {
//    margin: auto;
//    width: 100%;
//    max-width: 30em;
//    border: 1px solid #86c140;
//    padding: 10px;
//    margin-top: 20px;
//    margin-bottom: 20px;
//
//  }
//}



@font-face {
  font-family: 'Wingdings Regular';    //This is what we are going to call
  //src: url('../assets/fonts/VarelaRegular.ttf');
  src: url('assets/fonts/Wingdings-Regular-Font.ttf');
}

@font-face {
  font-family: 'Proxima Nova Bold';    //This is what we are going to call
  //src: url('../assets/fonts/VarelaRegular.ttf');
  src: url('assets/fonts/proxima bold/Proxima Nova Bold.otf');
}

@font-face {
  font-family: 'Proxima Nova Semi Bold';    //This is what we are going to call
  //src: url('../assets/fonts/VarelaRegular.ttf');
  src: url('assets/fonts/FontsFree-Net-Proxima-Nova-Sbold.otf');
}
@font-face {
  font-family: 'Proxima Nova';    //This is what we are going to call
  //src: url('../assets/fonts/VarelaRegular.ttf');
  src: url('assets/fonts/Proxima Nova Font.otf');
}
//@font-face {
//  font-family: 'Lato';  //This is what we are going to call
//  src: url('./assets/fonts/LatoRegular.ttf');
//}
